import React, { useEffect } from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';

const CustomModal = ({children, isOpen, onOpen, onClose, modalHeader}) => {

  useEffect(() => {
    const handleBackPress = () => {
      if (isOpen) {
        onClose();
      }
    };
    window.addEventListener("popstate", handleBackPress);

    return () => {
      window.removeEventListener("popstate", handleBackPress);
    };
  }, [isOpen, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalHeader}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;