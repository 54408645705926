import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: "loader",
  initialState: {
    isLoading: false,
  },
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },
    removeLoading: (state) => {
      state.isLoading = false;
    }
  }
});

export const {
  setLoading,
  removeLoading,
} = loaderSlice.actions;

export default loaderSlice.reducer;
