import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { removeLoading, setLoading } from '../reducer/loaderSlice';
import { refreshStudentAttendanceData, setStudentData } from '../reducer/studentSlice';
import { sagaActions } from '../sagaActions';
import { jwtHeader } from '../../utils/AuthManager';
import { toast } from 'react-toastify';

function* fetchStudentData() {
  try {
    yield put(setLoading());
    const response = yield call(() =>
  axios.get(`${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/desk/student/all`, {
    headers: jwtHeader(),
  }).then((res) => res)
  );
  console.log('Response from API:', response);

    if (response?.status === 200) {
      yield put(setStudentData({ studentData: response.data, error: '' }));

    } else {
      toast.error('Something went wrong, Please try again');
      yield put(setStudentData({studentData: [], error: 'Something went wrong'}));
    }
  } catch (error) {
    toast.error(error.message);
    yield put(setStudentData({studentData: [], error: error.message}));
  } finally {
    yield put(removeLoading());
  }
}

function* updateStudentAttendance(action) {
  try {
    yield put(setLoading());

    const {student_id, status_id, comment, callback, setSearchString} = action.payload;
    const requestData = {student_id, status_id, comment};

    const response = yield call(() => axios.put(`${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/desk/student/update/status`, requestData, {
      headers: jwtHeader(),
    }).then((res) => res));

    if (response?.status === 200) {
      yield put(refreshStudentAttendanceData(response.data));
      callback();
      setSearchString("");
      toast.success("Update Success");
    } else {
      toast.error('Something went during update');
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    yield put(removeLoading());
  }
}

export default function* studentSaga() {
  yield takeLatest(sagaActions.FETCH_STUDENT_DATA, fetchStudentData);
  yield takeLatest(sagaActions.UPDATE_STUDENT_STATUS, updateStudentAttendance);
}