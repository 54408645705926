import { getCookie } from '../CookieManager';
import { isExpired } from 'react-jwt';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import React from 'react';
import { resetProfileData } from '../../store/reducer/profileSlice';
import { resetAlumniData } from '../../store/reducer/aluminiSlice';
import { resetStudentData } from '../../store/reducer/studentSlice';

const AuthMiddleware = ({children}) => {
  const dispatch = useDispatch();
  const profileData = useSelector(state => state.profile);
  const token = getCookie('desk_reg_jwt');
  const isValid = token !== null && !isExpired(token);
  const {role} = profileData;

  if(isValid) {
    return <Navigate to="/" />;
  } else {
    // Remove all the unwanted state when logging out automatically
    if(role !== '') {
      dispatch(resetProfileData());
      dispatch(resetAlumniData());
      dispatch(resetStudentData());
    }

    return <>{children}</>
  }
}

export default AuthMiddleware;