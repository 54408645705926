export const yearColumnDefs = [
  {
    field: 'yearId',
    headerName: 'ID',
  },
  {
    field: 'year',
    headerName: 'Year Name',
    cellStyle: { fontWeight: 'bold' },
  },
  {
    field: 'notfilled',
    headerName: 'Not Fill Count',
  },
  {
    field: 'present',
    headerName: 'Present Count',
  },
  {
    field: 'absent',
    headerName: 'Absent Count',
  },
  {
    field: 'total',
    headerName: 'Total Count',
  },
];
