import { createSlice } from '@reduxjs/toolkit';

const initialStudentData = {
  data: [],
  error: '',
};

const studentSlice = createSlice({
  name: 'student',
  initialState: initialStudentData,
  reducers: {
    setStudentData: (state, { payload: data }) => {
      state.data = data.studentData;
      state.error = data.error;
    },
    refreshStudentAttendanceData: (state, {payload: data}) => {
      const statusObject = data.reduce((result, currentData) => {
        const {student_id, status, comment} = currentData;
        result[student_id] = {status, comment};

        return result;
      }, {});

      state.data = state.data.map((studentData) => ({
        ...studentData,
        ...statusObject[studentData.student_id],
      }));
    },
    resetStudentData: () => {
      return initialStudentData;
    },
  },
});

export const {
  setStudentData,
  resetStudentData,
  refreshStudentAttendanceData,
} = studentSlice.actions;

export default studentSlice.reducer;