import { all } from 'redux-saga/effects';
import studentSaga from './saga/studentSaga';
import alumniSaga from './saga/aluminiSaga';
import optionSaga from './saga/optionSaga';

export default function* rootSaga() {
    yield all([
        studentSaga(),
        alumniSaga(),
        optionSaga(),
    ]);
}