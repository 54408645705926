import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { removeLoading, setLoading } from '../reducer/loaderSlice';
import { refreshAlumniAttendanceData, setAlumniData } from '../reducer/aluminiSlice';
import { sagaActions } from '../sagaActions';
import { jwtHeader } from '../../utils/AuthManager';
import { toast } from 'react-toastify';

function* fetchAlumniData() {
  try {
    yield put(setLoading());
    const response = yield call(() => axios.get(`${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/desk/reg-alumni/all`, {
      headers: jwtHeader()
    }).then((res) => res));
    if (response?.status === 200) {
      yield put(setAlumniData({studentData: response.data, error: ''}));
    } else {
      yield put(setAlumniData({studentData: [], error: 'Data not found'}));
    }
  } catch (error) {
    yield put(setAlumniData({studentData: [], error: error.message}));
  } finally {
    yield put(removeLoading());
  }
}

function* updateAlumniAttendance(action) {
  try {
    yield put(setLoading());

    const {reg_alumni_id, status_id, comment, callback, setSearchString} = action.payload;
    const requestData = {reg_alumni_id, status_id, comment};

    const response = yield call(() => axios.put(`${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/desk/reg-alumni/status/update`, requestData, {
      headers: jwtHeader(),
    }).then((res) => res));

    if (response?.status === 200) {
      yield put(refreshAlumniAttendanceData(response.data));
      callback();
      setSearchString("");
      toast.success("Update Success");
    } else {
      toast.error('Something went during update');
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    yield put(removeLoading());
  }
}

export default function* alumniSaga() {
  yield takeLatest(sagaActions.FETCH_ALUMNI_DATA, fetchAlumniData);
  yield takeLatest(sagaActions.UPDATE_ALUMNI_STATUS, updateAlumniAttendance);
}