import { createSlice } from '@reduxjs/toolkit';

const initialOptionData = {
  attendance: {
    data: [],
    error: '',
  }
}

const optionSlice = createSlice({
  name: 'option',
  initialState: initialOptionData,
  reducers: {
    setOption: (state, {payload: data}) => {
      const {optionValue, optionName, error} = data;

      state[optionName].data = optionValue;
      state[optionName].error = error;
    },
    resetOptions: () => {
      return initialOptionData;
    },
  }
})

export const {
  setOption,
  resetOptions,
} = optionSlice.actions

export default optionSlice.reducer;