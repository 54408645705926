import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga"
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

let sagaMiddleware=createSagaMiddleware()
const middleware = [sagaMiddleware]

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(middleware),
  devTools: true,
});

sagaMiddleware.run(rootSaga);

export default store;