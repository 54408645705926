export const attendanceColumnDefs = [
  {
    field: 'statusId',
    headerName: 'ID',
  },
  {
    field: 'status',
    headerName: 'Status Name',
    cellStyle: { fontWeight: 'bold' },
  },

  {
    field: 'total',
    headerName: 'Status Count',
  },
];
