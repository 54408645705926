import React, { createRef, useEffect, useState } from 'react';
import DataItem from './DataItem';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../store/sagaActions';
import { Button, Input } from '@chakra-ui/react';
import CustomMenu from '../CustomModal/CustomMenu';
import ChangePageTitle from '../ChangePageTitle';

const AlumniModal = ({selectedAlumniData, closeDialog, setSearchString}) => {
  const {
    reg_alumni_id,
    name,
    year_passed,
    phone_number,
    email,
    college_name,
    status: { status_name = 'Not Filled', status_id = 3 } = {},
    comment = '',
    gender: { gender_name },
    days_attending,
  } = selectedAlumniData;
  const {data: attendanceOption, error: attendanceError} = useSelector(state => state.option.attendance)
  const dispatch = useDispatch();

  const commentSectionRef = createRef();
  const [selectedStatusCode, setSelectedStatusCode] = useState({status_name, status_id});

  useEffect(() => {
    if(attendanceOption.length === 0 && attendanceError === '') {
      dispatch({type: sagaActions.FETCH_OPTION_DATA});
    }
  }, []);

  const updateData = () => {
    const {status_id = 3} = selectedStatusCode;

    dispatch({
      type: sagaActions.UPDATE_ALUMNI_STATUS,
      payload: {
        "reg_alumni_id": reg_alumni_id,
        "status_id": status_id,
        "comment": commentSectionRef.current.value,
        "callback": closeDialog,
        "setSearchString": setSearchString,
      }
    });
  }

  return (
    <div className='custom-modal student-modal'>
      <ChangePageTitle pageTitle='Update Attendance - Sangamam 6.0'></ChangePageTitle>
      <div className='custom-modal__row'>
        <DataItem label={'Alumni ID'} value={`A${String(reg_alumni_id).padStart(4, '0')}`} />
        <DataItem label={'Year'} value={year_passed} />
      </div>

      <div className='custom-modal__row'>
        <DataItem label={'Name'} value={name} />
        <DataItem label={'Gender'} value={gender_name} />
      </div>

      <div className='custom-modal__row'>
        <DataItem label={'Email'} value={email} />
      </div>

      <div className='custom-modal__row'>
        <DataItem label={'College'} value={college_name} />
      </div>

      <div className='custom-modal__row'>
        <DataItem label={'Phone Number'} value={phone_number} />
        <DataItem label={'Room No'} value={'Nil'} />
      </div>

      <div className='custom-modal__row'>
        <DataItem label={'Days Attending'} value={days_attending} />
      </div>

      <div className='custom-modal__row'>
        <div className="select-field">
          <div className="select-field__label">Attendance</div>
          <CustomMenu setSelectedOption={setSelectedStatusCode} selectedOption={selectedStatusCode} options={attendanceOption} />
        </div>
      </div>

      <div className='custom-modal__row'>
        <div className="input-field">
          <div className="select-field__label">Comment</div>
          <Input colorScheme={'blue'} bg={'#FFF'} ref={commentSectionRef} my={1} defaultValue={comment}></Input>
        </div>
      </div>

      <div className='custom-modal__row'>
        <Button onClick={updateData} type={"submit"} bg='#fdc400' color='#000' w='100%' my={3} _hover={{ bg: '#fdc400' }}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default AlumniModal;