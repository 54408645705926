export const genderColumnDefs = [
  {
    field: 'genderId',
    headerName: 'ID',
  },
  {
    field: 'gender',
    headerName: 'Gender Name',
    cellStyle: { fontWeight: 'bold' },
  },
  {
    field: 'notfilled',
    headerName: 'Not Fill Count',
  },
  {
    field: 'present',
    headerName: 'Present Count',
  },
  {
    field: 'absent',
    headerName: 'Absent Count',
  },
  {
    field: 'total',
    headerName: 'Total Count',
  },
];
