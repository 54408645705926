import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { defaultColDefinition } from '../../utils/data/DefaultColumn';
import { studentsColumnDefs } from './StudentColumns';
import { sagaActions } from '../../store/sagaActions';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';
import CustomModal from '../CustomModal/CustomModal';
import StudentModal from '../DataModal/StudentModal';
import { useSearchParams } from 'react-router-dom';

const StudentTable = ({searchString, from, setSearchString}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const gridRef = useRef();
  const dispatch = useDispatch();
  const studentDetails = useSelector(state => state.student);
  const {data: studentData, error} = studentDetails;

  const [studentQueryData, setStudentQueryData] = useState([]);
  const [filteredStudentData, setFilteredStudentData] = useState([]);
  const [selectedStudentData, setSelectedStudentData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const [columnDefs] = useState(studentsColumnDefs);
  const gridStyle = { height: '100%', width: '100%' };

  useEffect(() => {
    if (studentData.length === 0 && error === '') {
      dispatch({ type: sagaActions.FETCH_STUDENT_DATA });
    }
  }, [dispatch, studentData.length, error]);

  useEffect(() => {
    const queryParams = {};

    searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    if(Object.keys(queryParams).length === 0) {
      setStudentQueryData(studentData);
    } else {
      const queriedData = studentData.filter((studentDetail) => {
        let returnCondition = true;

        Object.keys(queryParams).forEach((queryKey) => {
          if(studentDetail[queryKey]?.[`${queryKey}_id`].toString() !== queryParams[queryKey]) {
            returnCondition = false;
            return;
          }
        });

        return returnCondition;
      });
      setStudentQueryData(queriedData);
    }
  }, [studentData, searchParams]);

  useEffect(() => {
    const newStudentList = studentQueryData.filter((studentDetail) => studentDetail["student_id"].toString().toLowerCase().includes(searchString.toLowerCase()) || studentDetail["name"].toLowerCase().includes(searchString.toLowerCase()))
    setFilteredStudentData(newStudentList);
  }, [searchString, studentQueryData]);

  const handleRowSelect = (event) => {
    setSelectedStudentData(event.data);
    onOpen();
  }

  return (
    <div style={gridStyle} className='ag-theme-alpine'>
      <AgGridReact
        onRowClicked={handleRowSelect}
        ref={gridRef}
        rowData={searchString ? filteredStudentData : studentQueryData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDefinition}
        suppressExcelExport={true}
      ></AgGridReact>

      <CustomModal modalHeader={"Update Attendance"} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <StudentModal selectedStudentData={selectedStudentData} closeDialog={onClose} setSearchString={setSearchString} />
      </CustomModal>
    </div>
  );
};

export default StudentTable;