import { useMemo, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../store/sagaActions';
import Navbar from '../../../../Components/Nav';
import ChangePageTitle from '../../../../Components/ChangePageTitle';
import { attendanceColumnDefs } from './AttendanceColumns';
import { defaultColDefinition } from '../../../../utils/data/DefaultColumn';
import { useNavigate } from 'react-router-dom';

export default function FilterAttendance() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const studentDetails = useSelector((state) => state.student);
  const { data: studentData, error } = studentDetails;

  useEffect(() => {
    if (studentData.length === 0 && error === '') {
      dispatch({ type: sagaActions.FETCH_STUDENT_DATA });
    }
  }, [dispatch, studentData.length, error]);

  const rowData = useMemo(() => {
    if(studentData.length === 0) return [];

    const statusObject = studentData.reduce((prevData, currentStudent) => {
      const {status: {status_name, status_id}} = currentStudent;

      if(!prevData.hasOwnProperty(status_id)) {
        prevData[status_id] = {statusId: status_id, status: status_name, total: 0}
      }

      prevData[status_id].total += 1;

      return prevData;
    }, {});

    return Object.values(statusObject);
  }, [studentData]);

  const handleClick = (event) => {
    navigate(`/students?status=${event.data.statusId}`);
  };

  return (
    <>
      <ChangePageTitle pageTitle='Sangamam 6.0 | Count - Attendance' />
      <Navbar breadCrumb={['Analytics', 'Student-Attendance']}>
          <div className='ag-theme-alpine table-without-search'>
            <AgGridReact
              rowData={rowData}
              columnDefs={attendanceColumnDefs}
              defaultColDef={defaultColDefinition}
              suppressExcelExport={true}
              onRowClicked={handleClick}
            />
          </div>
      </Navbar>
    </>
  );
}
