import { createSlice } from '@reduxjs/toolkit';

const initialAlumniData = {
  data: [],
  error: '',
};

const alumniSlice = createSlice({
  name: 'alumni',
  initialState: initialAlumniData,
  reducers: {
    setAlumniData: (state, { payload: data }) => {
      state.data = data.studentData;
      state.error = data.error;
    },
    refreshAlumniAttendanceData: (state, {payload: data}) => {
      const statusObject = data.reduce((result, currentData) => {
        const {reg_alumni_id, status, comment} = currentData;
        result[reg_alumni_id] = {status, comment};

        return result;
      }, {});

      state.data = state.data.map((alumniData) => ({
        ...alumniData,
        ...statusObject[alumniData.reg_alumni_id],
      }));
    },
    resetAlumniData: () => {
      return initialAlumniData;
    },
  },
});

export const {
  setAlumniData,
  resetAlumniData,
  refreshAlumniAttendanceData,
} = alumniSlice.actions;

export default alumniSlice.reducer;
