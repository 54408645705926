import Navbar from '../../../Components/Nav';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { useState} from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS
import ChangePageTitle from '../../../Components/ChangePageTitle';
import { FiSearch } from 'react-icons/all';
import StudentTable from '../../../Components/StudentTable/StudentTable';
import AlumniTable from '../../../Components/AlumniTable/AlumniTable';
import { CloseIcon } from '@chakra-ui/icons';
import { ACTIONS } from '../../../utils/DateStuff';
import { useSelector } from 'react-redux';

export default function Attendance() {
  const profileData = useSelector(state => state.profile);
  const { role: userRole } = profileData;
  const [searchString, setSearchString] = useState('')
  const [currentTab, setCurrentTab] = useState(1);

  const handleChange = (event) => setSearchString(event.target.value);
  const selectedTab = (currentTab === 1) ? <StudentTable searchString={searchString} setSearchString={setSearchString} from={ACTIONS.ATTENDANCE} /> : <AlumniTable searchString={searchString} setSearchString={setSearchString} from={ACTIONS.ATTENDANCE} />;

  const tabSelector = () => {
    return (
      <div className="tab-selector">
        <div className={`tab students-tab ${(currentTab === 1) ? 'active' : ''}`} onClick={() => setCurrentTab(1)}>
          <Text fontSize={'md'} fontWeight={'medium'} color={'#323232'}>Student</Text>
        </div>
        <div className={`tab alumni-tab ${(currentTab === 2) ? 'active' : ''}`} onClick={() => setCurrentTab(2)}>
          <Text fontSize={'md'} fontWeight={'medium'} color={'#323232'}>Alumni</Text>
        </div>
      </div>
    );
  };

  return (
    <>
      <ChangePageTitle pageTitle="Attendance - Sangamam 6.0"></ChangePageTitle>
      <Navbar breadCrumb={['Actions', 'Attendance', (currentTab === 1) ? 'Students' : 'Alumni']}>
        <div className="search-bar-section">

          <div className="search-bar-field">
            <InputGroup>
              <Input
                variant="filled"
                bg="white"
                _focus={{
                  bg: 'white',
                }}
                placeholder='Enter Search String'
                value={searchString}
                onChange={handleChange}
              />
              <InputRightElement>
                <FiSearch />
              </InputRightElement>
            </InputGroup>

            <IconButton
              ml='8px'
              colorScheme='red'
              aria-label='Clear Icon'
              icon={<CloseIcon />}
              onClick={() => setSearchString('')}
            />
          </div>

          {userRole === "Admin" && tabSelector()}
        </div>

        <div className='table-with-search table-with-tab'>
          {selectedTab}
        </div>

      </Navbar>
    </>
  );
}
