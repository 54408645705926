import studentReducer from './reducer/studentSlice';
import alumniReducer from './reducer/aluminiSlice';
import loaderReducer from './reducer/loaderSlice';
import profileSlice from './reducer/profileSlice';
import optionSlice from './reducer/optionSlice';

const rootReducer = {
    student: studentReducer,
    alumni: alumniReducer,
    loader: loaderReducer,
    profile: profileSlice,
    option: optionSlice,
};

export default rootReducer;