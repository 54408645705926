import React from 'react';
import { Navigate } from 'react-router-dom';
import { getCookie } from '../CookieManager';
import { decodeToken, isExpired } from 'react-jwt';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileData } from '../../store/reducer/profileSlice';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const profileData = useSelector(state => state.profile);
  const dispatch = useDispatch();
  const token = getCookie('desk_reg_jwt');
  const isValid = token !== null && !isExpired(token)

  if(isValid) {
    const {role, user_id: userId, user_name: userName} = decodeToken(token);
    const isAuthorised = allowedRoles.includes(role);

    if(profileData.role === '') {
      dispatch(setProfileData({
        userId,
        userName,
        role,
        token,
      }));
    }

    if(isAuthorised) {
      return <>{children}</>
    } else {
      return role === 'Admin' ? <Navigate to="/" /> : <Navigate to="/attendance" />
    }
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
