import React, { createRef, useEffect, useState } from 'react';
import DataItem from './DataItem';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../store/sagaActions';
import { Button, Input } from '@chakra-ui/react';
import CustomMenu from '../CustomModal/CustomMenu';
import ChangePageTitle from '../ChangePageTitle';
import { toast } from 'react-toastify';

const StudentModal = ({selectedStudentData, closeDialog, setSearchString}) => {
  const profileData = useSelector(state => state.profile);
  const { role: userRole } = profileData;
  const {student_id, name, department_name, gender: {gender_name}, year: {year_name}, college: {college_name}, status: {status_name = "Not Filled", status_id: preStatusId = 3}, comment = "", room_info} = selectedStudentData;
  const {data: attendanceOption, error: attendanceError} = useSelector(state => state.option.attendance)
  const dispatch = useDispatch();

  const commentSectionRef = createRef();
  const [selectedStatusCode, setSelectedStatusCode] = useState({status_name, status_id: preStatusId});

  useEffect(() => {
    if(attendanceOption.length === 0 && attendanceError === '') {
      dispatch({type: sagaActions.FETCH_OPTION_DATA});
    }
  }, []);

  const updateData = () => {
    if(userRole !== "Admin" && preStatusId !== 3) {
      toast.error("Contact admin to update");
      return;
    }

    const {status_id = 3} = selectedStatusCode;

    dispatch({
      type: sagaActions.UPDATE_STUDENT_STATUS,
      payload: {
        "student_id": student_id,
        "status_id": status_id,
        "comment": commentSectionRef.current.value,
        "callback": closeDialog,
        "setSearchString": setSearchString,
      }
    });
  }

  return (
    <div className='custom-modal student-modal'>
      <ChangePageTitle pageTitle='Update Attendance - Sangamam 6.0'></ChangePageTitle>
      <div className='custom-modal__row'>
        <DataItem label={'Student ID'} value={`S${String(student_id).padStart(4, '0')}`} />
        <DataItem label={'Year'} value={year_name} />
      </div>

      <div className='custom-modal__row'>
        <DataItem label={'Name'} value={name} />
        <DataItem label={'Gender'} value={gender_name} />
      </div>

      <div className='custom-modal__row'>
        <DataItem label={'College'} value={college_name} />
      </div>

      <div className='custom-modal__row'>
        <DataItem label={'Department'} value={department_name} />
        <DataItem label={'Room No'} value={room_info} />
      </div>

      <div className='custom-modal__row'>
        <div className="select-field">
          <div className="select-field__label">Attendance</div>
          <CustomMenu setSelectedOption={setSelectedStatusCode} selectedOption={selectedStatusCode} options={attendanceOption} />
        </div>
      </div>

      <div className='custom-modal__row'>
        <div className="input-field">
          <div className="select-field__label">Comment</div>
          <Input colorScheme={'blue'} bg={'#FFF'} ref={commentSectionRef} my={1} defaultValue={comment}></Input>
        </div>
      </div>

      <div className='custom-modal__row'>
        <Button onClick={updateData} type={"submit"} bg='#fdc400' color='#000' w='100%' my={3} _hover={{ bg: '#fdc400' }}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default StudentModal;