import {
  Box, Button, Flex, FormControl,
  FormLabel, Heading, Input, InputGroup, InputRightElement,
  Stack, useColorModeValue,
} from '@chakra-ui/react';
import React, { createRef, useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { deleteCookie, getCookie, setCookie } from '../utils/CookieManager';
import ChangePageTitle from '../Components/ChangePageTitle';
import { useNavigate } from 'react-router-dom';
import { decodeToken } from 'react-jwt';
import { toast } from 'react-toastify';

export default function Login() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const email = createRef();
  const password = createRef();

  const handleClick = () => {
    setShow(!show);
  };

  const submitHandler = (e) => {
    e.preventDefault();
        
    const _email = email.current.value;
    const _password = password.current.value;

    var loginFormData = new FormData();
    loginFormData.append('username', _email);
    loginFormData.append('password', _password);
    axios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT + '/login',
      data: loginFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then((response) => {

      if (getCookie('desk_reg_jwt') !== null) {
        deleteCookie('desk_reg_jwt');
      }
      const accessToken = response.data.access_token;
      const { role } = decodeToken(accessToken);

      setCookie('desk_reg_jwt', accessToken);

      if (role === 'Admin') {
        navigate('/');
      } else {
        navigate('/attendance');
      }
    }).catch((e) => {
      if(e.response) {
        if(e.response.status == "403") {
          toast.error("Invalid login Credentials");
        }
      } else {
        toast.error("Something went wrong, Try again");
      }
    })
  };

  return (
    <>
      <ChangePageTitle pageTitle='Sangamam 6.0 Desk Panel | Login'></ChangePageTitle>
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} w={[400, 500, 600]} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Sign in to your account</Heading>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack as={'form'} spacing={5} onSubmit={submitHandler}>
              <FormControl>
                <FormLabel>Email address</FormLabel>
                <Input
                  required
                  ref={email}
                  type='email'
                  placeholder='Enter email'
                />
              </FormControl>
              <FormControl id='password'>
                <FormLabel>Password</FormLabel>
                <InputGroup size='md'>
                  <Input
                    required
                    name='password'
                    pr='4.5rem'
                    ref={password}
                    type={show ? 'text' : 'password'}
                    placeholder='Enter password'
                  />
                  <InputRightElement onClick={handleClick} _hover={{ cursor: 'pointer' }}>
                    {show ? <ViewOffIcon /> : <ViewIcon />}
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack>
                <Button
                  mt="12px"
                  type={'submit'}
                  boxShadow={
                    '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                  }
                  bg='#fdc400'
                  color='#000'
                  _hover={{
                    bg: '#fdc400',
                  }}>
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </>
  );
}
