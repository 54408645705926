export const collegeColumnDefs = [
  {
    field: 'collegeId',
    headerName: 'ID',
  },
  {
    field: 'college',
    sortable: true,
    headerName: 'College Name',
    cellStyle: { fontWeight: 'bold' },
  },
  {
    field: 'notfilled',
    headerName: 'Not Fill Count',
  },
  {
    field: 'present',
    headerName: 'Present Count',
  },
  {
    field: 'absent',
    headerName: 'Absent Count',
  },
  {
    field: 'total',
    headerName: 'Total Count',
  },
];
