export const alumniColumnDefs = [
    {
        field: "reg_alumni_id",
        headerName: "Alumni ID",
        cellRendererParams: {
            color: '#e7b100'
        },
        cellRenderer: (data) => {
            return `A${String(data.value).padStart(4, '0')}`;
        },
    },
    {
        field: "name",
        cellStyle: { fontWeight: 'bold' },
    },
    {
        field: "year_passed",
        headerName: "Passed Out Year"
    },
    {
        field: "phone_number",
        headerName: "Phone Number"
    },
    {
        field: "gender.gender_name",
        headerName: "Gender"
    },
    {
        field: "status.status_name",
        headerName: "Status"
    },
    {
        field: "email",
        headerName: "Email"
    },
    {
        field: "college_name",
        headerName: "College"
    }
]
