import React, { useMemo } from 'react';
import {
  Box,
  Flex,
  HStack,
  VStack,
  IconButton,
  CloseButton,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Avatar,
  MenuDivider,
  DrawerOverlay,
  Divider, Breadcrumb, BreadcrumbItem,
} from '@chakra-ui/react';
import {
  FiMenu, FiBook, FiUsers, FiLogOut,
} from 'react-icons/fi';
import { deleteCookie } from '../utils/CookieManager';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetProfileData } from '../store/reducer/profileSlice';
import { resetAlumniData } from '../store/reducer/aluminiSlice';
import { resetStudentData } from '../store/reducer/studentSlice';
import { ChevronRightIcon } from '@chakra-ui/icons';

const navItems = [
  {
    navCategory: '',
    itemList: [{
      name: 'Dashboard',
      href: '/',
      icon: FiBook,
      allowedRoles: ['Admin'],
    }],
  }, {
    navCategory: 'Analytics',
    itemList: [{
      name: 'Student-Gender',
      href: '/filter-by-gender',
      icon: FiUsers,
      allowedRoles: ['Admin'],
    },
    {
      name: 'Student-Attendance',
      href: '/filter-by-attendance/display-all',
      icon: FiUsers,
      allowedRoles: ['Admin'],
    },
    {
      name: 'Student-Year',
      href: '/filter-by-year/display-all',
      icon: FiUsers,
      allowedRoles: ['Admin'],
    },
    {
      name: 'Student-College',
      href: '/filter-by-college/display-all',
      icon: FiUsers,
      allowedRoles: ['Admin'],
    },
  ],
  }, {
    navCategory: 'Members',
    itemList: [{
      name: 'Students',
      href: '/students',
      icon: FiBook,
      allowedRoles: ['Admin'],
    }, {
      name: 'Alumni',
      href: '/alumni',
      icon: FiBook,
      allowedRoles: ['Admin'],
    }],
  }, {
    navCategory: 'Actions',
    itemList: [{
      name: 'Attendance',
      href: '/attendance',
      icon: FiUsers,
      allowedRoles: ['Desk', 'Admin'],
    }],
  }];

export default function SidebarWithHeader({ children, breadCrumb = [] }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (<Box minH='100vh' bg={useColorModeValue('gray.100', 'gray.900')}>
    <SidebarContent
      onClose={() => onClose}
      display={{ base: 'none', md: 'block' }}
    />
    <Drawer
      autoFocus={false}
      isOpen={isOpen}
      placement='left'
      onClose={onClose}
      returnFocusOnClose={false}
      onOverlayClick={onClose}
      size='xs'
    >
      <DrawerOverlay>
        <DrawerContent w={'250px'} maxW={'250px'} m={'20px'} borderRadius='20px'>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
    <TopNavBar onOpen={onOpen} />
    <Box ml={{ base: 0, md: 60 }} p='4'>
      <Breadcrumb spacing='8px' mb='16px' separator={<ChevronRightIcon color='gray.500' />}>
        {
          breadCrumb.map((item) => (
            <BreadcrumbItem key={item}>
              <Text fontSize={'sm'} fontWeight={'noraml'} color={'#323232'} mt={"3px"}>{item}</Text>
            </BreadcrumbItem>
          ))
        }
      </Breadcrumb>

      {children}
    </Box>
  </Box>);
}

const SidebarContent = ({ onClose, ...rest }) => {
  const profileData = useSelector(state => state.profile);
  const { role: userRole } = profileData;

  const roleBasedNavItems = useMemo(() => {
    const navList = [];

    navItems.forEach((navItem) => {
      const categoryList = [];
      navItem.itemList.forEach((itemData) => {
        if (itemData.allowedRoles.includes(userRole)) {
          categoryList.push(itemData);
        }
      });

      if (categoryList.length > 0) {
        navList.push({
          ...navItem, itemList: categoryList,
        });
      }
    });

    return navList;
  }, [userRole]);

  return (<Box
    borderRadius='20px'
    transition='3s ease'
    bg={useColorModeValue('white', 'gray.900')}
    borderRight='1px'
    borderRightColor={useColorModeValue('gray.200', 'gray.700')}
    w={{ base: 'full', md: 60 }}
    pos='fixed'
    h='full'
    overflowY={'scroll'}
    {...rest}>
    <Flex my='6' textAlign={'center'} mx='2' justifyContent='space-between'>
      <Text fontSize='xl' fontWeight='bold' mt='1px' pl='6px'>
        Sangamam 6.0
      </Text>
      <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
    </Flex>
    <Divider my={4} />
    {roleBasedNavItems.map(({ navCategory, itemList }) => (
      <>
        <Text key={navCategory} ml={'3'} fontWeight={'bold'}>{navCategory}</Text>
        {itemList.map((itemData) => (
          <NavItem key={itemData.name} icon={itemData.icon} href={itemData.href}>
            {itemData.name}
          </NavItem>
        ))}
      </>
    ))}
  </Box>);
};

const NavItem = ({ icon, href, children, ...rest }) => {
  const navigate = useNavigate();

  return (<Link style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} onClick={() => navigate(href)}>
    <Flex
      align='center'
      p='2'
      my='2'
      mx='4'
      borderRadius='lg'
      role='group'
      cursor='pointer'
      bg={window.location.pathname === href ? '#fdc400' : '#FFF'}
      fontWeight={window.location.pathname === href ? 'medium' : 'normal'}
      color={window.location.pathname === href ? '#000' : '#5b5b5b'}
      {...rest}>
      {icon && (<Icon
        mr='4'
        fontSize='16'
        as={icon}
      />)}
      {children}
    </Flex>
  </Link>);
};

const TopNavBar = ({ onOpen, ...rest }) => {
  const dispatch = useDispatch();
  const profileData = useSelector(state => state.profile);
  const { userName: name, role } = profileData;
  const navigate = useNavigate();

  const logout = () => {
    dispatch(resetProfileData());
    dispatch(resetAlumniData());
    dispatch(resetStudentData());

    deleteCookie('desk_reg_jwt');
    navigate('/login');
  };

  return (<Flex
    ml={{ base: 0, md: 60 }}
    px={{ base: 3, md: 3 }}
    height='20'
    alignItems='center'
    bg={useColorModeValue('white', 'gray.900')}
    borderBottomWidth='1px'
    borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
    justifyContent={{ base: 'space-between', md: 'flex-end' }}
    {...rest}>

    <IconButton
      display={{ base: 'flex', md: 'none' }}
      onClick={onOpen}
      size='sm'
      aria-label='open menu'
      variant='unstyled'
      fontSize='28px'
      icon={<FiMenu />}
    />

    <Text
      display={{ base: 'flex', md: 'none' }}
      fontSize='xl'
      fontFamily='monospace'
      fontWeight='bold'>
      Sangamam 6.0
    </Text>

    <HStack spacing={{ base: '0', md: '6' }}>
      <Flex alignItems={'center'}>
        <Menu>
          <MenuButton
            py={2}
            transition='all 0.3s'
            _hover={{ backgroundColor: 'gray.100' }}
            _focus={{ boxShadow: 'none' }}
            pt={{ base: '0', md: '1' }} pr={{ base: '0', md: '2' }} pb={{ base: '0', md: '1' }} pl={{ base: '0', md: '2' }}
            borderRadius={6}
          >
            <HStack>
              <Box>
                <Avatar bg='#fdc400' name={name} src='https://bit.ly/broken-link' size='md' />
              </Box>
              <VStack
                display={{ base: 'none', md: 'flex' }}
                alignItems='flex-start'
                spacing='1px'>
                <Text fontSize='md' fontWeight='medium'>{name}</Text>
                <Text fontSize='xs' color='gray.600'>{role}</Text>
              </VStack>
            </HStack>
          </MenuButton>
          <MenuList
            bg={useColorModeValue('white', 'gray.900')}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
          >
            <VStack
              pl={3}
              pr={3}
              display={{ base: 'block', md: 'none' }}
              alignItems='flex-start'
              spacing='1px'>
              <Text fontSize='md' fontWeight='medium'>{name}</Text>
              <Text fontSize='xs' color='gray.600'>{role}</Text>
            </VStack>
            <MenuDivider display={{ base: 'block', md: 'none' }} />
            <MenuItem icon={<FiLogOut size={20} />} onClick={() => {
              logout();
            }}>
              <Text fontSize='md'>Sign out</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </HStack>
  </Flex>);
};
