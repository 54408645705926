import Navbar from '../../../Components/Nav';
import { Text } from '@chakra-ui/react';
import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS
import ChangePageTitle from '../../../Components/ChangePageTitle';

export default function Dashboard() {
  return (
    <>
      <ChangePageTitle pageTitle='Sangamam 6.0 Desk Panel | All Registrations'></ChangePageTitle>
      <Navbar>
        <Text fontSize={'3xl'} fontWeight={'bold'} color={'#323232'} my='5'>All Students</Text>
      </Navbar>
    </>
  );
}
