import { createSlice } from '@reduxjs/toolkit';

const initialProfileState = {
  userId: '',
  userName: '',
  role: ''
}

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialProfileState,
  reducers: {
    setProfileData: (state, {payload: data}) => {
      state.userId = data.userId;
      state.userName = data.userName;
      state.role = data.role;
    },
    resetProfileData: (state) => {
      return initialProfileState;
    },
  }
});

export const {
  setProfileData,
  resetProfileData,
} = profileSlice.actions;

export default profileSlice.reducer;