
export const studentsColumnDefs = [
    {
        field: "student_id",
        headerName: "Student ID",
        cellRendererParams: {
            color: '#e7b100'
        },
        cellRenderer: (data) => {
            return `S${String(data.value).padStart(4, '0')}`;
        },
    },
    {
        field: "name",
        cellStyle: { fontWeight: 'bold' },
    },
    {
        field: "college.college_name",
        headerName: "College"
    },
    {
        field: "year.year_name",
        headerName: "Year"
    },
    {
        field: "department_name",
        headerName: "Department"
    },
    {
        field: "gender.gender_name",
        headerName: "Gender"
    },
    {
        field: "status.status_name",
        headerName: "Status"
    }
]
